



























































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import { UserListObj, UserAddParams, Department } from './types'
import { Form } from 'element-ui'

@Component({ name: 'DepartmentList' })
export default class DepartmentList extends VueBase {
  private dialogFormVisible = false
  private loadingFlag = false
  private options = []
  async remoteMethod(query: string) {
    this.loadingFlag = true
    const res = await this.services.user.userList({
      page: 1,
      pageSize: 1000,
      keywords: query,
    })
    this.loadingFlag = false
    if (res.status === 201) {
      this.options = res.data
    } else {
      this.options = []
    }
  }
  private type = 'add'
  private keyword = ''

  // 用于表格数据变化时保存当前展开的节点
  private expandedKeys: any = []
  private onExpand(expanded: any, record: any) {
    if (expanded) {
      // 设置展开窗Key
      this.onExpandedRowsChange(record)
    } else {
      if (this.expandedKeys.length) {
        this.expandedKeys = this.expandedKeys.filter((v: any) => {
          return v != record.id
        })
      }
    }
  }
  private onExpandedRowsChange(rows: any) {
    this.expandedKeys.push(rows.id)
  }
  // 设置默认展开
  private getFindRowsKey(ids: any, tree: any) {
    //   for (let i = 0; i < tree.length; i++) {
    //     const node = tree[i]
    //     if (node.children) {
    //       this.getFindRowsKey(ids, node.children)
    //     } else {
    //       return
    //     }
    //     console.log('node', i, node.parentId, tree)
    //     // if (node.parentId + '' && !ids.includes(node.parentId)) {
    //     //   ids.push(node.parentId)
    //     // }
    //   }
    return ids
  }
  // 为每个字节点添加父节点ID
  private giveParentId(arr: any) {
    // for (let i = 0; i < arr.length; i++) {
    //   const node = arr[i]
    //   if (node.children) {
    //     this.giveParentId(node.children)
    //   } else {
    //     return
    //   }
    //   node.children.forEach((item: any) => {
    //     item.parentId = node.id
    //   })
    // }
    return arr
  }

  private async addDepart(row: any) {
    const res = await this.services.user.userList({
      page: 1,
      pageSize: 100,
    })
    if (res.status === 201) {
      this.options = res.data
    }
    this.type = 'add'
    this.dialogFormVisible = true
    this.form.id = 0
    this.form.talent = row.departmentId
    this.form.parent = row.id > 0 ? row.id : -1
    this.form.principal_id = ''
  }
  private async editDepart(row: any) {
    this.type = 'edit'
    const res = await this.services.user.userList({
      page: 1,
      pageSize: 100,
    })
    if (res.status === 201) {
      this.options = res.data
    }
    this.dialogFormVisible = true
    this.form.talent = row.departmentId
    this.form.id = row.id
    this.form.name = row.label
    this.form.principal_id = row.principal_id
  }

  private async deleteDepart(row: any) {
    const params = { talent: 0, id: 0 }
    params.talent = row.departmentId
    params.id = row.id
    this.$confirm(this.$t('views.strategyManage.confirmDel') as string, '', {
      confirmButtonText: this.$t('views.strategyManage.confirm') as string,
      cancelButtonText: this.$t('views.strategyManage.cancel') as string,
      type: 'warning',
    }).then(async () => {
      const res = await this.services.department.departmentDel(row.id, params)
      if (res.status === 201) {
        this.$message.success(res.msg)
        this.departmentList()
      } else {
        this.$message.error(res.msg)
      }
    })
  }
  private newSelectData() {
    this.departmentList()
  }
  private enterAdd() {
    const ruleForm: any = this.$refs.ruleForm
    ruleForm.validate(async (valid: any) => {
      if (valid) {
        if (this.form.name === '') {
          this.$message.warning(
            this.$t('views.strategyManage.twname') as string
          )
          return
        }
        let fromDate = { ...this.form }
        if (!fromDate.principal_id) {
          Reflect.deleteProperty(fromDate, 'principal_id')
        }
        let res: any
        switch (this.type) {
          case 'add':
            res = await this.services.department.departmentAdd(fromDate)
            break
          case 'edit':
            res = await this.services.department.departmentUpdate(
              this.form.id,
              fromDate
            )
            break
        }
        if (res.status === 201) {
          this.$message.success(res.msg)
          this.dialogFormVisible = false
          this.departmentList()
          this.form = {
            id: 0,
            name: '',
            parent: 0,
            talent: 0,
            principal_id: '',
          }
        } else {
          this.$message.error(res.msg)
        }
      }
    })
  }
  private form = {
    name: '',
    parent: 0,
    id: 0,
    talent: 0,
    principal_id: '',
  }
  private rules = {
    name: [
      {
        required: true,
        message: this.$t('views.strategyManage.twname') as string,
        trigger: 'change',
      },
    ],
    // principal_id: [
    //   {
    //     required: true,
    //     message: '请选择部门负责人',
    //     trigger: 'change',
    //   },
    // ],
  }
  private tableData = []
  fmtOptions(options: Array<any>, id: number) {
    options.forEach((item: any) => {
      if (id) {
        item.departmentId = id
      } else {
        item.departmentId = -item.id
      }
      if (item.children.length) {
        this.fmtOptions(item.children, item.departmentId)
      } else {
        delete item.children
      }
    })
  }
  async departmentList() {
    const res = await this.services.user.departmentList()
    if (res.status === 201) {
      res.data = res.data.filter(
        (item: any) => item.label.indexOf(this.keyword) > -1
      )
      this.fmtOptions(res.data, 0)
      this.tableData = res.data
      this.tableData = this.giveParentId(this.tableData)
    }
  }
  async created() {
    await this.departmentList()
    this.expandedKeys = this.getFindRowsKey(this.expandedKeys, this.tableData)
  }
}
